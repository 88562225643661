import {
  BulkRequest,
  BulkResponse,
  ServicesCatalogServer,
} from '@wix/ambassador-services-catalog-server/http';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { EmptyStateType } from '../types/sevice-catalog.types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';

const CATALOG_SERVER_URL = '_api/services-catalog';

export const getCatalogService = async ({
  serviceId,
  wixSdkAdapter,
  onError,
}: {
  serviceId: string;
  wixSdkAdapter: WixOOISDKAdapter;
  onError: ControllerParams['flowAPI']['reportError'];
}): Promise<BulkResponse | undefined> => {
  const baseUrl = wixSdkAdapter.getServerBaseUrl();
  const authorization = wixSdkAdapter.getInstance();
  const catalogServer = ServicesCatalogServer(
    `${baseUrl}${CATALOG_SERVER_URL}`,
  );
  const filter = JSON.stringify({
    'service.id': { $eq: serviceId },
  });

  const servicesCatalogService = catalogServer.Bulk();
  const bulkRequest: BulkRequest = {
    requestServices: {
      includeDeleted: false,
      query: {
        fieldsets: [],
        filter,
        paging: {
          limit: 500,
        },
        fields: [],
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };

  try {
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: authorization,
    }).get(bulkRequest);
    return catalogData;
  } catch (e: any) {
    reportError(e);
    onError(EmptyStateType.SERVER_ERROR);
  }
};

export const getCatalogServiceWithBusinessLocation = async ({
  wixSdkAdapter,
  onError,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  onError: ControllerParams['flowAPI']['reportError'];
}): Promise<BulkResponse | undefined> => {
  const baseUrl = wixSdkAdapter.getServerBaseUrl();
  const authorization = wixSdkAdapter.getInstance();
  const catalogServer = ServicesCatalogServer(
    `${baseUrl}${CATALOG_SERVER_URL}`,
  );
  // const filter = JSON.stringify({
  //   'service.schedules.availability.locations.businessLocation.default': {
  //     $eq: true,
  //   },
  // });

  const servicesCatalogService = catalogServer.Bulk();
  const bulkRequest: BulkRequest = {
    requestServices: {
      includeDeleted: false,
      query: {
        fieldsets: [],
        // filter,
        paging: {
          limit: 20,
        },
        fields: [],
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };

  try {
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: authorization,
    }).get(bulkRequest);
    return catalogData;
  } catch (e: any) {
    reportError(e);
    onError(EmptyStateType.SERVER_ERROR);
  }
};

export const getOneService = async ({
  wixSdkAdapter,
  onError,
}: {
  wixSdkAdapter: WixOOISDKAdapter;
  onError: ControllerParams['flowAPI']['reportError'];
}): Promise<BulkResponse | undefined> => {
  const baseUrl = wixSdkAdapter.getServerBaseUrl();
  const authorization = wixSdkAdapter.getInstance();
  const catalogServer = ServicesCatalogServer(
    `${baseUrl}${CATALOG_SERVER_URL}`,
  );

  const servicesCatalogService = catalogServer.Bulk();
  const bulkRequest: BulkRequest = {
    requestServices: {
      includeDeleted: false,
      query: {
        fieldsets: [],
        paging: {
          limit: 1,
        },
        fields: [],
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };

  try {
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: authorization,
    }).get(bulkRequest);
    return catalogData;
  } catch (e: any) {
    reportError(e);
    onError(EmptyStateType.SERVER_ERROR);
  }
};

export const getOneServiceV2 = async (authorization, onError) => {
  const CATALOG_READER_BASE_URL = '/_api/services-catalog';

  const servicesCatalogServer = ServicesCatalogServer(
    `${CATALOG_READER_BASE_URL}`,
  );
  const servicesCatalogService = servicesCatalogServer.Bulk();

  const bulkRequest: BulkRequest = {
    requestServices: {
      includeDeleted: false,
      query: {
        fieldsets: [],
        paging: {
          limit: 1,
        },
        fields: [],
        sort: [],
      },
    },
    requestBusiness: {
      suppressNotFoundError: false,
    },
  };

  try {
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: authorization,
    }).get(bulkRequest);
    return catalogData;
  } catch (e: any) {
    reportError(e);
    onError(EmptyStateType.SERVER_ERROR);
  }
};
